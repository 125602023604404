import React, {useRef} from "react";
import { useEffect } from "react";
import { useState } from "react";

export const Discount = ({ total,oldOrder }) => {

    const [discount, setDiscount] = useState(null);
    const select = useRef(null);
    const [calculatedDiscount,setCalculatedDiscount]=useState(0);
    async function getDiscounts() {
        const params = new URLSearchParams(window.location.search);
        const api_url = "https://ryby24h.pl/app-api/";
        const farmid = params.get("fishingfarm");
        const response = await fetch(api_url + farmid + "/get_discounts", {
            method: "GET",
        });
        if (response.ok){
            const jsonData = await response.json();
            setDiscount(jsonData);
        }
        else {
            return null;
        }
    }
    useEffect(() => {
        const signal = new AbortController();
        getDiscounts(signal);
        return () => {
            signal.abort();
        };
    },[]);
    const handleChange = event => {
        const index = event.target.selectedIndex;
        const el = event.target.childNodes[index]
        const id =  el.getAttribute('id');
        let discount=( event.target.value*total) / 100
        discount.toFixed(2)
        setCalculatedDiscount((total-discount).toFixed(2))
        oldOrder["customer"]["discount"]=id
    }
    if (discount){
        return (
            <div className="row-span-1">
                <div className="flex justify-end py-4 px-2">
                    <p className="px-2">Ulgi:</p>
                    <select
                        ref={select}
                        onChange={handleChange}
                        className="px-3 py-1 shadow-md rounded-md"
                    >
                        <option id="" value="0">Wybierz</option>
                        {discount.map((e) => {
                            return (
                                <option id={e.id} key={e.id} value={e.discount}>
                                    {e.name} ( {e.discount} %)
                                </option>
                            );
                        })}
                    </select>
                </div>
                {calculatedDiscount > 0 &&  Math.round(calculatedDiscount)!==total ? <p style={{color:"red"}} className="text-end px-2 font-semibold my-2">Cena po obniżce: {calculatedDiscount} zł</p>: null }
            </div>
        );
    }
};
