import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import Choice from "./choice";
import CalenarComponent from "../calendar/component";

const Order = ({ onItemChange, onOrderItemComplete, items,updateActiveButton,itemRemoved,setFooter,prevStage }) => {
  const [lakes, setLakes] = useState([]);
  const [allLakes, setAllLakes] = useState([]);
  const [durations, setDurations] = useState([]);
  const [seasons, setSeasons] = useState([]);
  const [methods, setMethods] = useState([]);
  const [stage, setStage] = useState(0); //min 0 - max 5
  const [actualLakeOrder, setactualLakeOrder] = useState({
    lake: null,
    duration: null,
    season: null,
    method: null,
    last_action: null,
  });
  if (!lakes.find((element) => element.id === itemRemoved)&&itemRemoved!==null)
  {
    let lake=allLakes.find((element) => element.id === itemRemoved);
    if(lake!==undefined){
      lakes.push(lake)
      lakes.sort(function(a, b) {
        if (a.id < b.id) return -1;
        if (a.id > b.id) return 1;
        return 0;
      })
    }
  }
  async function getFromApi(jsonData, controller, get_back) {
    const params = new URLSearchParams(window.location.search);
    const api_url = "https://ryby24h.pl/app-api/";
    const farmid = params.get("fishingfarm");

    switch (actualLakeOrder.last_action) {
      case "set_duration":
        return null;
        break;
      case "set_lake":
        jsonData = {
          ...jsonData,
          duration: null,
          season: null,
          method: null,
        };
        break;
      case "set_season":
        jsonData = {
          ...jsonData,
          duration: null,
          method: null,
        };
        break;
      case "set_method":
        jsonData = {
          ...jsonData,
          duration: null,
        };
        break;
    }

    const response = await fetch(api_url + farmid + "/get_data", {
      method: "POST",
      signal: controller.signal,
      body: JSON.stringify({ ...jsonData, get_back: get_back }),
    });

    const jsonData_ = await response.json();
    if(allLakes.length === 0 ){
      setAllLakes(jsonData_.lake)
    }
    const lake = jsonData_.lake.filter((e) => {
      const elem_find = items.find((elem) => {
        if (elem.lake !== null) {
          if (e.id === elem.lake.id) {
            return e;
          }
        }
      });
      if (elem_find === undefined) {
        return e;
      }
    });

    jsonData_.lake = lake;

    get_back.forEach((element) => {
      switch (actualLakeOrder.last_action) {
        case "set_lake":
          setSeasons(jsonData_.season);
          setDurations(jsonData_.duration);
          setMethods(jsonData_.method);
          break;
        case "set_season":
          setDurations(jsonData_.duration);
          setMethods(jsonData_.method);
          break;
        case "set_method":
          setDurations(jsonData_.duration);
          break;
        default:
          setSeasons(jsonData_.season);
          setDurations(jsonData_.duration);
          setMethods(jsonData_.method);
          setLakes(jsonData_.lake);
      }
    });
  }

  useEffect(() => {
    const controller = new AbortController();
    getFromApi(actualLakeOrder, controller, [
      "lake",
      "duration",
      "season",
      "method",
    ]);
    return () => {
      controller.abort();
    };
  }, []);

  useEffect(() => {
    const controller = new AbortController();
    getFromApi(actualLakeOrder, controller, [
      "lake",
      "duration",
      "season",
      "method",
    ]);

    return () => {
      controller.abort();
    };
  }, [actualLakeOrder]);

  const waterChoiceFunc = (e) => {
    setactualLakeOrder((prev) => {
      return { ...prev, lake: e.id, last_action: "set_lake" };
    });
    setStage(1);
    updateActiveButton(false)
    onItemChange({ lake: e });
  };

  const seasonChoiceFunc = (e) => {
    setactualLakeOrder((prev) => {
      return { ...prev, season: e.id, last_action: "set_season" };
    });
    setStage(2);
    onItemChange({ season: e });
  };

  const methodChoiceFunc = (e) => {
    setactualLakeOrder((prev) => {
      return { ...prev, method: e.id, last_action: "set_method" };
    });
    setStage(3);
    onItemChange({ method: e });
  };

  const refresh = (e, toOrder) => {
    if (e !== null)
      setactualLakeOrder((prev) => {
        return { ...prev, ...e };
      });

    onItemChange(toOrder);
  };

  const resetOrder = () => {
    setactualLakeOrder((prev) => {
      return {
        ...prev,
        lake: null,
        duration: null,
        season: null,
        method: null,
        last_action: null,
      };
    });
    setStage(0);
    onItemChange({
      duration: null,
      lake: null,
      price: null,
      date: { from: null, to: null },
      method: null,
      season: null,
    });
    if (items.length>1){
      updateActiveButton(true)
    }
    setFooter([{
      class: "btn-primary",
      text: "Cofnij",
      action: prevStage,
      disabled: false,
    },])
  };

  const acceptItem = () => {
    setactualLakeOrder(() => {
      return {
        lake: null,
        duration: null,
        season: null,
        method: null,
        last_action: null,
      };
    });
    onOrderItemComplete();
    setStage(0);
    setTimeout(function(){
      window.scrollTo(0, document.documentElement.scrollHeight);
    }, 100);

  };

  return (
    <div className="col-span-3 md:col-span-2  bg-fishes-blue-disabled rounded-md py-5 px-3 grid grid-cols-6 gap-2">
      {stage < 3 ? (
        <>
          <div className="col-span-6 md:col-span-2">
            <Choice
              onSelect={waterChoiceFunc}
              status={stage === 0 ? true : false}
              name="Wybór jeziora"
              ico="bi bi-geo text-fishes-blue-dark"
              items={lakes}
            />
          </div>
          <div className="col-span-6 md:col-span-2">
            <Choice
              onSelect={seasonChoiceFunc}
              status={stage === 1 ? true : false}
              name="Wybór sezonu"
              ico="bi bi-cloud-drizzle text-fishes-blue-dark"
              items={seasons}
            />
          </div>
          <div className="col-span-6 md:col-span-2">
            <Choice
              onSelect={methodChoiceFunc}
              status={stage === 2 ? true : false}
              name="Wybór metody"
              ico="bi bi-hand-index text-fishes-blue-dark"
              items={methods}
            />
          </div>
        </>
      ) : (
        <div className="col-span-6">
          <CalenarComponent
            order={actualLakeOrder}
            seasons={{ get: seasons }}
            durations={{ get: durations }}
            methods={{ get: methods }}
            onRefresh={refresh}
            onGoBack={resetOrder}
            onAgree={acceptItem}
          ></CalenarComponent>
        </div>
      )}
    </div>
  );
};

export default Order;
